import cx from 'classnames';
import Image from 'next/image';
import { Grid, GridSize, Hidden, makeStyles } from '@material-ui/core';
import { H2_Legacy, P_Legacy, ResponsiveGrid, matchesSelectedVariant, WithExperimentProps } from 'components';
import { HomepageBlade } from 'services/cms';
import React from 'react';
import { CallToActionButton } from 'components/cms/call-to-action';

type Props = WithExperimentProps & {
  blade: HomepageBlade;
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColour: ({ blade }: Props) => blade.backgroundColour,
    paddingTop: 80,
    paddingBottom: 80,
    [theme.breakpoints.down('md')]: {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  content: {
    alignItems: 'center',
    flexDirection: ({ blade }: Props) => (blade.isLeftAligned ? 'row-reverse' : 'row'),
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: () => 'column-reverse' /* without this css specificity is f'd by prior declaration */,
      alignItems: 'stretch',
      alignContent: 'space-around',
    },
  },
  contentText: {},
  contentImage: {
    overflowX: 'visible',
    '& div': {
      float: ({ blade }: Props) => (blade.isLeftAligned ? 'right' : 'left'),
      margin: '0 auto',
    },
    [theme.breakpoints.down('md')]: {
      '& div': {
        float: () => 'left' /* without this, css specifity cannot be overwritten */,
        overflowX: 'inherit',
        width: '80%',
        height: 'auto',
        '& img': {
          width: '100%',
          height: 'auto',
        },
      },
    },
  },
  button: {
    marginTop: '40px',
    '& a': { margin: '0 !important' },
  },
  header: {
    whiteSpace: 'pre-line',
  },
  body: {
    whiteSpace: 'pre-line',
  },
  unselectable: {
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    '& *': {
      userSelect: 'none',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
    },
  },
}));

export const HomeBlade = (props: Props) => {
  const {
    blade: {
      heading,
      content,
      ctaText,
      ctaLink,
      trackingEventAction,
      textGridSize,
      image,
      imageGridSize,
      experimentVariant,
      gtmEventCategory,
      gtmEventAction,
    },
  } = props;
  const classes = useStyles(props);

  if (!matchesSelectedVariant(props.selectedVariant, experimentVariant.name)) {
    return null;
  }

  return (
    <ResponsiveGrid className={classes.root} gridContainerClassName={classes.content}>
      <Hidden only={['xs', 'sm', 'lg', 'xl']}>
        <Grid item md={6} className={cx(classes.unselectable, classes.button)}>
          {ctaText && ctaLink && (
            <CallToActionButton
              href={ctaLink}
              text={ctaText}
              fullWidth={true}
              trackingEventAction={trackingEventAction}
              {...{ gtmEventCategory, gtmEventAction }}
            />
          )}
        </Grid>
      </Hidden>
      <Grid item xs={12} md={8} lg={(textGridSize || 6) as GridSize} className={classes.contentText}>
        <H2_Legacy className={classes.header} variant="hero">
          {heading}
        </H2_Legacy>
        <P_Legacy className={classes.body}>{content}</P_Legacy>
        <Hidden only={'md'}>
          <div className={cx(classes.unselectable, classes.button)}>
            {ctaText && ctaLink && (
              <CallToActionButton
                href={ctaLink}
                text={ctaText}
                trackingEventAction={trackingEventAction}
                {...{ gtmEventCategory, gtmEventAction }}
              />
            )}
          </div>
        </Hidden>
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        lg={(imageGridSize || 5) as GridSize}
        className={cx(classes.unselectable, classes.contentImage)}
      >
        <Image
          src={image.url}
          alt={image.alt}
          title={image.title}
          width={image.width / 2}
          height={image.height / 2}
          layout="intrinsic"
          objectFit="contain"
        />
      </Grid>
    </ResponsiveGrid>
  );
};
