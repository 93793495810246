import React, { FC } from 'react';
import { Image } from 'react-datocms';
import { makeStyles } from '@material-ui/core';
import { Image as ImageDataType } from 'services/cms';
import cx from 'classnames';

type Props = {
  coverImage: ImageDataType;
  mastheadImage: ImageDataType;
  fullHeight?: boolean;
};

const useStyles = makeStyles({
  coverImage: {
    height: '100%',
    '& img ': {
      objectFit: 'cover',
    },
  },
  mastheadImage: {
    position: 'absolute!important' as never,
    bottom: 8,
    left: 8,
    transform: 'scale(0.5)',
    transformOrigin: 'bottom left',
  },
  wrapperHeight: { height: '100%' },
  wrapperRelative: { position: 'relative' },
});

export const ArticleImage: FC<Props> = ({ coverImage, mastheadImage, fullHeight }) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.wrapperRelative, fullHeight ? classes.wrapperHeight : '')}>
      <Image className={classes.coverImage} data={coverImage.responsiveImage} />
      {mastheadImage && <Image className={classes.mastheadImage} data={mastheadImage.responsiveImage} />}
    </div>
  );
};
