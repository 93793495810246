import { PageOptions } from 'components/true-page';
import { useEffect } from 'react';
import { GenericPage, isStructuredTextEmpty, usePageInfo } from 'services/cms';
import { DeepPartial } from 'utils/deep-partial';
import { MetaData } from './meta-data';

type Props = {
  page: GenericPage;
  setOptions?: (options: DeepPartial<PageOptions>) => void;
};

export function PageInfo({ page, setOptions }: Props) {
  const pageInfo = usePageInfo(page);

  useEffect(() => {
    if (setOptions) {
      const hasFooter = !isStructuredTextEmpty(pageInfo?.footer?.value);
      const hasPageLinks = !isStructuredTextEmpty(pageInfo?.pageLinks?.value);
      if (hasFooter && hasPageLinks) {
        setOptions({
          cmsPageLinks: pageInfo.pageLinks,
          footer: {
            pageFooters: [pageInfo.footer?.value],
          },
        });
      } else if (hasFooter) {
        setOptions({
          footer: {
            pageFooters: [pageInfo.footer?.value],
          },
        });
      } else if (hasPageLinks) {
        setOptions({
          cmsPageLinks: pageInfo.pageLinks,
        });
      }
    }
  }, [pageInfo, setOptions]);

  return (
    <>
      <MetaData tags={pageInfo?.seo} />
    </>
  );
}
