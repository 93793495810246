import React, { FC } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { H3, Link, P } from 'components';
import { ArticlePreview } from '../types';
import dayjs from 'dayjs';
import { ArticleImage } from '.';
import { usePageInfo } from 'services/cms';

type Props = {
  mode: 'carousel' | 'normal';
  article: ArticlePreview;
};

const useStyles = makeStyles((theme) => ({
  article: {
    userSelect: 'none',
    position: 'relative',
    '&:hover > div > div': {
      opacity: 0.1,
    },
    margin: '16px 0',
    '& a': {
      color: theme.palette.Vegemite.main,
      textDecoration: 'none',
    },
  },
  contentWrapper: {
    padding: '16px 16px 50px 16px',
  },
  background: {
    backgroundColor: theme.palette.Pavlova.main,
    position: 'relative',
    height: '100%',
  },
  body2: {
    overflow: 'hidden',
    '-webkit-line-clamp': '3',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
  },
  source: {
    height: '18px',
    bottom: '16px',
    width: 'calc(100% - 32px)',
    position: 'absolute',
  },
  darkOverlay: {
    pointerEvents: 'none',
    opacity: 0,
    backgroundColor: 'black',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  h3: {
    margin: '8px 0',
  },
  floatRight: { float: 'right' },
}));

export const Article: FC<Props> = ({ article, mode }) => {
  const classes = useStyles();

  const pageInfo = usePageInfo(article);
  const articleSource =
    article.press && article.publishedIn
      ? `Originally published by ${article.publishedIn}`
      : article.author?.name
      ? `Written by ${article.author?.name}`
      : null;

  return (
    <Grid item xs={mode === 'normal' ? 12 : 9} md={mode === 'normal' ? 6 : 5} lg={4} className={classes.article}>
      <div className={classes.background}>
        <Link href={`/articles/${article.slug}`} underline="none">
          <ArticleImage coverImage={article.coverImage} mastheadImage={article.mastheadImage} />
          <div className={classes.contentWrapper}>
            {article.tag?.label && (
              <P variant="cap2" marginTop="none" textTransform="uppercase" className={classes.floatRight}>
                {article.tag?.label}
              </P>
            )}
            <P marginTop="none" variant="cap2">
              {dayjs(article.date).format('DD.MM.YYYY')}
            </P>
            <H3 variant="sub2" className={classes.h3}>
              {pageInfo?.title}
            </H3>
            <P variant="b2" className={classes.body2}>
              {article.blurp}
            </P>
            <P variant="cap2" className={classes.source}>
              {articleSource}
            </P>
          </div>
        </Link>
        <div className={classes.darkOverlay}></div>
      </div>
    </Grid>
  );
};
