import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { makeStyles, useTheme } from '@material-ui/core';
import {
  ResponsiveGrid,
  Footer,
  SiteMap,
  matchesSelectedVariant,
  PageInfo,
  SocialAsSeenIn,
  SocialQuoteAndSource,
  SocialTestimonials,
} from 'components';
import { useAuth } from 'context/auth';
import { pages as homeUrls } from 'features/home/urls';
import {
  HomeHeroBlade,
  HomeBlade,
  HomeRatesBlade,
  HomeLenderMarquee,
  HomeTrueCanvasBlade,
  HomeTrueCanvasContentBlade,
} from 'features/home/components';
import { Header, TruePage } from 'components';
import {
  Homepage,
  responsiveImageFields,
  GlobalFooter,
  ARTICLE_PREVIEW_6,
  pageInfoFields,
  usePageInfo,
  gqlStaticProps,
} from 'services/cms';
import { defaultPublicPageLinks } from 'models/pageLinks';
import { useFeatureFlags } from 'context';
import { ReferralBanner } from 'features/referrals/components';
import { useGlobalCmsData } from 'hooks/use-global-cms-data';
import { ArticlePreview } from 'features/public/types';
import { ArticlesCarousel } from 'features/public/components';

interface Props {
  page: Homepage;
  globalFooter: GlobalFooter;
  allArticles: ArticlePreview[];
}

const useStyles = makeStyles((theme) => ({
  stickyWrapper: {
    zIndex: 2,
    position: 'relative',
  },
  articleBack: {
    backgroundColor: theme.palette.Dark9.main,
    padding: '80px 0 80px',
  },
}));

const Page: TruePage<Props> = ({ page, allArticles, globalFooter }) => {
  const { push } = useRouter();
  const { user, isUserInitialised } = useAuth();
  const classes = useStyles();
  const theme = useTheme();
  const featureFlags = useFeatureFlags();
  const { data: cmsData } = useGlobalCmsData();

  // Homepage experiment has now ended, so we can set to Original
  const selectedVariant = 'Original';
  const pageInfo = usePageInfo(page); // required for non-standard footer

  useEffect(() => {
    if (user && isUserInitialised) {
      push(homeUrls.HOME_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isUserInitialised]);

  if (!isUserInitialised || user) {
    return null;
  }

  return (
    <>
      <PageInfo page={page} />
      <ReferralBanner />
      {page.heroBlade?.length > 0 && (
        <div className={classes.stickyWrapper} style={{ zIndex: 7 }}>
          <Header
            backgroundColor={page.heroBlade[0].backgroundColour?.hex || theme.palette.SpongeCake.main}
            textColor={page.heroBlade[0].headerTextColour?.hex || theme.palette.Vegemite.main}
            pageLinks={Page.options.pageLinks}
            isSticky
          />
          <HomeHeroBlade
            heading={page.heroBlade[0].heading}
            backgroundColour={page.heroBlade[0].backgroundColour?.hex || theme.palette.SpongeCake.main}
            image={page.heroBlade[0].image}
            ctas={page.heroCtas}
            facts={page.heroFacts}
            selectedVariant={selectedVariant}
          />
        </div>
      )}
      {matchesSelectedVariant(selectedVariant, page.testimonialsExperimentVariant.name) && page.heroBlade?.length > 0 && (
        <div className={classes.stickyWrapper} style={{ zIndex: 6 }}>
          <Header
            backgroundColor={theme.palette.Dark8.main}
            textColor={page.heroBlade[0].headerTextColour?.hex || theme.palette.Vegemite.main}
            pageLinks={Page.options.pageLinks}
            isSticky
          />
          <SocialAsSeenIn images={page.asSeenIn} />
          <SocialQuoteAndSource quoteAndSource={page.quoteAndSource} />
          <SocialTestimonials testimonials={page?.customerTestimonials} />
        </div>
      )}
      {page.blades
        .filter((blade) => matchesSelectedVariant(selectedVariant, blade.experimentVariant?.name))
        .map((blade, index) => (
          <div
            key={blade.id}
            className={classes.stickyWrapper}
            style={{ backgroundColor: blade.backgroundColour.hex, zIndex: 6 }}
          >
            <Header
              backgroundColor={blade.backgroundColour.hex}
              textColor={blade.headerTextColour.hex}
              pageLinks={Page.options.pageLinks}
              isSticky
            />
            <HomeBlade blade={blade} selectedVariant={selectedVariant} />
            {index === 0 && page.homeloanRates.length > 0 && (
              <>
                <HomeRatesBlade homeloanRates={page.homeloanRates || []} />
                <HomeLenderMarquee lenderImages={page.lenderMarquee} />
              </>
            )}
          </div>
        ))}
      {/* True Canvas Blade */}
      {featureFlags.canvas && (
        <div id="truecanvas" className={classes.stickyWrapper} style={{ backgroundColor: theme.palette.Vegemite.main }}>
          <Header
            backgroundColor={theme.palette.Vegemite.main}
            textColor={theme.palette.Pavlova.main}
            pageLinks={Page.options.pageLinks}
            isSticky
            isDarkMode
          />
          <HomeTrueCanvasBlade
            blade={page?.canvasBlade?.pageContentBlade[0] as unknown as HomeTrueCanvasContentBlade}
          />
        </div>
      )}
      <div className={classes.stickyWrapper} style={{ backgroundColor: theme.palette.Pavlova.main, zIndex: 6 }}>
        <Header headerColor="pavlova" pageLinks={Page.options.pageLinks} isSticky />
        {featureFlags.homeArticleCarousel && (
          <ResponsiveGrid className={classes.articleBack}>
            <ArticlesCarousel articles={allArticles} />
          </ResponsiveGrid>
        )}
        {cmsData && <SiteMap data={cmsData} mode="light" isAuthenticated={false} />}
        <Footer
          content={[pageInfo?.footer?.value, globalFooter?.footer]}
          backgroundColour={theme.palette.Dark6.main}
          textColour={theme.palette.Dark2.main}
        />
      </div>
    </>
  );
};

Page.options = {
  headerColor: 'sponge-cake',
  layout: null,
  pageLinks: [...defaultPublicPageLinks],
};

export default Page;

export const getStaticProps = gqlStaticProps<Props>(
  `
  query GetHomepage {
    page: homepage {
      pageInfo {
        ...pageInfoFields
      }
      homeloanRates {
        rate
        rateType
      }
      heroBlade {
        heading
        image {
          ...simpleImageFields
        }
        backgroundColour {
          hex
        }
        headerTextColour {
          hex
        }
      }
      heroFacts {
        value
      }
      heroCtas: experimentHeroCtas {
        ...callToActionFields
      }
      blades: experimentBlades {
        id
        heading
        content
        textGridSize
        imageGridSize
        image {
          ...simpleImageFields
        }
        isLeftAligned
        ctaText
        ctaLink
        trackingEventAction
        gtmEventCategory
        gtmEventAction
        backgroundColour {
          hex
        }
        headerTextColour {
          hex
        }
        experimentVariant {
          name
        }
      }
      lenderMarquee {
        ...simpleImageFields
      }
      asSeenIn {
        ...simpleImageFields
      }
      quoteAndSource {
        value
      }
      customerTestimonials {
        name
        quote
        subText
        profilePicture {
          ...simpleImageFields
        }
      }
      testimonialsExperimentVariant {
        name
      }
      canvasBlade {
        pageContentBlade: blocks {
          image {
            alt
            title
            url
            width
            height
          }
          content {
            value
          }
        }
      }
      helpingBuy
      helpingInvest
      helpingRefinance
      introducing
      scrollHeight
      heroImage {
        responsiveImage(imgixParams: {fm: png, fit: crop, dpr: 2}) {
          ...responsiveImageFields
        }
      }
      canvasImage {
        responsiveImage(imgixParams: {fm: png, fit: crop, dpr: 2}) {
          ...responsiveImageFields
        }
      }
      canvasInfo {
        responsiveImage(imgixParams: {fm: png, fit: crop, dpr: 2}) {
          ...responsiveImageFields
        }
      }
      canvasAtAGlance {
        responsiveImage(imgixParams: {fm: png, fit: crop, dpr: 2}) {
          ...responsiveImageFields
        }
      }
    }
    globalFooter: globalConfiguration {
      footer {
        value
      }
    }
    ${ARTICLE_PREVIEW_6}
  }
  ${pageInfoFields}
  ${responsiveImageFields}
  `,
  {
    revalidate: 60 /* seconds */,
  }
);
