import { Box, Grid, makeStyles } from '@material-ui/core';
import type { PositionProperty } from 'csstype';
import { ResponsiveGrid, StructuredText } from 'components';
import { MediaContent } from 'components/cms';
import { PageContentBlade, SimpleImage } from 'services/cms';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    color: theme.palette.Pavlova.main,
    padding: '100px 0',
    zIndex: 6,

    [theme.breakpoints.down('sm')]: {
      padding: '50px 0',
    },
  },
  gridContainer: {
    flexDirection: 'row',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  heading2: {
    color: theme.palette.Pavlova.main,
    fontSize: '54px',
    [theme.breakpoints.only('md')]: {
      fontSize: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '28.8px',
    },
  },
  heading3: {
    color: theme.palette.Pavlova.main,
    marginTop: '56px',
    fontSize: '24px',
  },
  paragraph: {
    marginTop: '16px',
  },
  laptopContainer: {
    position: 'sticky !important' as PositionProperty,
    maxWidth: (props: { imageWidth?: number }) =>
      props.imageWidth ? `${Math.floor(props.imageWidth / 2)}px` : 'inherit',
    top: 130,
    [theme.breakpoints.down('sm')]: {
      position: 'relative !important' as PositionProperty,
      top: 0,
      width: '100%',
    },
    [theme.breakpoints.only('md')]: {
      position: 'relative !important' as PositionProperty,
      top: 0,
      width: 'calc( 100% + 80px)',
    },
  },
}));

export type HomeTrueCanvasContentBlade = Omit<PageContentBlade, 'image'> & { image: SimpleImage };

type Props = {
  // image on this blade is a gif and do not require responsive image
  blade: HomeTrueCanvasContentBlade;
};

export const HomeTrueCanvasBlade = ({ blade }: Props) => {
  const { content, image } = blade;
  const classes = useStyles({ imageWidth: image?.width });

  return (
    <Box className={classes.root}>
      <ResponsiveGrid gridContainerClassName={classes.gridContainer}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <StructuredText
            data={content}
            pVariant="b2"
            h2ClassName={classes.heading2}
            h3ClassName={classes.heading3}
            pClassName={classes.paragraph}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <MediaContent className={classes.laptopContainer} image={image} />
        </Grid>
      </ResponsiveGrid>
    </Box>
  );
};
