import Image from 'next/image';
import { makeStyles, Grid, Theme } from '@material-ui/core';
import { ResponsiveGrid } from 'components';
import { SimpleImage } from 'services/cms';

type Props = {
  images: SimpleImage[];
  isDarkMode?: boolean;
};

const MAX_IMAGES_DISPLAYED = 7;

const useStyles = makeStyles<Theme, Pick<Props, 'isDarkMode'>>((theme) => ({
  root: {
    paddingTop: 50,
    paddingBottom: 50,
    backgroundColor: (props) => (props.isDarkMode ? theme.palette.Vegemite.main : theme.palette.Dark8.main),
  },
  imagesContainer: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
}));

export const SocialAsSeenIn = ({ images, isDarkMode = false }: Props) => {
  const classes = useStyles({ isDarkMode });

  if (images.length === 0) {
    return null;
  }

  const AsSeenInImages = images.map((image, i) => {
    if (i < MAX_IMAGES_DISPLAYED) {
      return (
        <div key={image.url}>
          <Image alt={image.alt} title={image.title} src={image.url} layout="intrinsic" height={40} width={124} />
        </div>
      );
    }
    return null;
  });

  return (
    <ResponsiveGrid className={classes.root}>
      <Grid item md={12}>
        <Grid container className={classes.imagesContainer}>
          {AsSeenInImages}
        </Grid>
      </Grid>
    </ResponsiveGrid>
  );
};
