import React from 'react';
import cx from 'classnames';
import Image from 'next/image';
import { Box, Grid, Hidden, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { H1_Legacy, ResponsiveGrid, WithExperimentProps } from 'components';
import { CallToAction, SimpleImage, SingleLineText } from 'services/cms';
import BalanceText from 'react-balance-text';
import { CallToActionButton } from 'components/cms/call-to-action';

type Props = WithExperimentProps & {
  heading: string;
  backgroundColour: string;
  image: SimpleImage;
  ctas?: CallToAction[];
  facts?: Array<SingleLineText>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '100px',
    background: (props: Props) => props.backgroundColour,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '80px',
    },
  },
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  unselectable: {
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    '& *': {
      userSelect: 'none',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
    },
  },
  contentImage: {
    textAlign: 'center',
    overflowX: 'visible',
  },
  contentText: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      textAlign: 'center',
      '& h1': {
        marginTop: 0,
      },
      '& a': { margin: '4px 0!important' },
    },
  },
  buttons: {
    marginTop: '1rem',
    display: 'flex',
    width: '100%',
    '& > a, & > button': {
      margin: '1rem 1rem 0 0',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 0',
      padding: '1.2rem 0',
      flexDirection: 'column',
      '& > a, & > button': {
        width: '100%',
        maxWidth: 'inherit',
      },
    },
  },
  check: {
    margin: '0 0 2px 4px',
    width: '16px',
    height: '16px',
    alignSelf: 'center',
  },
  title: {
    marginBottom: '0',
    letterSpacing: '-0.03em',
    fontSize: 'clamp(2.5rem, -0.875rem + 7.6vw, 62px)!important',
    width: '100%',
    maxWidth: '580px',
  },
  facts: {
    marginTop: '24px',
    [theme.breakpoints.only('md')]: {
      marginTop: 0,
      marginBottom: '24px',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  fact: {
    marginRight: '16px',
    display: 'flex',
    fontSize: '16px',
    height: '24px',
  },

  // eslint-disable-next-line mui-unused-classes/unused-classes
  '@keyframes mover': {
    '0%': { transform: 'translateY(0)' },
    '100%': { transform: 'translateY(-10px)' },
  },
}));

export function HomeHeroBlade(props: Props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ResponsiveGrid className={classes.root} gridContainerClassName={classes.content}>
      <Grid item lg={6} md={8} xs={12} className={cx(classes.contentImage, classes.unselectable)}>
        <Image
          src={props.image.url}
          alt={props.image.alt}
          title={props.image.title}
          width={(props.image.width / 2) | 0}
          height={(props.image.height / 2) | 0}
          layout={matches ? 'intrinsic' : 'fixed'}
          loading="eager"
        />
      </Grid>
      <Hidden lgUp>
        <Grid item container lg={6} xs={12} alignContent="center" className={classes.contentText}>
          <H1_Legacy variant="hero" className={classes.title}>
            <BalanceText>{props.heading}</BalanceText> {/*splits text evenly over multiple lines  */}
          </H1_Legacy>
        </Grid>
      </Hidden>
      <Grid item container xs={12} md={6} alignContent="center" className={classes.contentText}>
        <Hidden mdDown>
          <H1_Legacy variant="hero" className={classes.title}>
            <BalanceText>{props.heading}</BalanceText> {/*splits text evenly over multiple lines  */}
          </H1_Legacy>
        </Hidden>
        <div className={classes.buttons}>
          {props.ctas.map((cta) => (
            <CallToActionButton key={`${cta.text}-${cta.href}`} {...cta} selectedVariant={props.selectedVariant} />
          ))}
        </div>
        <Box mt={{ xs: 2, sm: 2, md: 0, lg: 2, xl: 2 }} mb={2}>
          <Grid container className={classes.facts}>
            {props.facts?.map((fact) => (
              <span key={fact.value} className={classes.fact}>
                {fact.value}
                <img
                  alt={`${fact.value} check`}
                  className={cx(classes.unselectable, classes.check)}
                  src="/images/home/check.svg"
                />
              </span>
            ))}
          </Grid>
        </Box>
      </Grid>
    </ResponsiveGrid>
  );
}
