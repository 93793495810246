import { HTMLAttributes } from 'react';
import { SimpleImage } from 'services/cms';

type Props = {
  image: SimpleImage;
} & HTMLAttributes<HTMLVideoElement>;

const isVideoFormat = (format: string) => ['gif', 'mp4', 'webm'].includes(format);

export const MediaContent = ({ image, ...others }: Props) => {
  const isVideo = isVideoFormat(image.format);

  if (!isVideo) {
    return <img className={others.className} src={image.url} alt={image.alt} />;
  }

  return (
    <video {...others} playsInline autoPlay muted preload="none" loop title={image.title}>
      <source src={`${image.url}?fm=mp4`} type="video/mp4" />
      <source src={`${image.url}?fm=webm`} type="video/webm" />
    </video>
  );
};
