import Image from 'next/image';
import Marquee from 'react-fast-marquee';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { SimpleImage } from 'services/cms';

type Props = {
  lenderImages: SimpleImage[];
};

const useStyles = makeStyles((theme) => ({
  // eslint-disable-next-line mui-unused-classes/unused-classes
  root: {
    animationName: '$overridescroll!important',
    paddingTop: 40,
    paddingBottom: 40,
    backgroundColor: '#003793',
    userSelect: 'none',
    '& *': {
      userSelect: 'none',
    },
  },
  lenderLogo: {
    margin: '0 40px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: '0 20px',
    },
  },
  svgFilters: {
    width: 0,
    height: 0,
    position: 'absolute',
    left: '-9999em',
  },
}));

export const HomeLenderMarquee = ({ lenderImages }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const Internal = lenderImages.map((lenderImage) => (
    <div key={lenderImage.url} className={classes.lenderLogo}>
      <Image
        alt={lenderImage.alt}
        title={lenderImage.title}
        src={lenderImage.url}
        layout="intrinsic"
        height={matches ? 40 : 60}
        width={lenderImage.width}
      />
    </div>
  ));

  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" className={classes.svgFilters}>
        <filter id="duotone">
          <feColorMatrix
            type="matrix"
            values="0 0 1 0 0.05
                    0 0 1 0 0.15
                    0 0 1 0 0.50
                    0 0 0 1 0"
          />
        </filter>
      </svg>
      <Marquee className={classes.root} gradient={false} play={true} pauseOnClick={false} pauseOnHover={false}>
        {Internal}
      </Marquee>
    </>
  );
};
