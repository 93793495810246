import { makeStyles, Grid, Theme } from '@material-ui/core';
import { ResponsiveGrid } from 'components';
import { StructuredText } from 'components/cms';
import { StructuredTextDocument } from 'react-datocms/dist/types/StructuredText';

type Props = {
  quoteAndSource: StructuredTextDocument;
  isDarkMode?: boolean;
};

const useStyles = makeStyles<Theme, Pick<Props, 'isDarkMode'>>((theme) => ({
  root: {
    backgroundColor: (props) => (props.isDarkMode ? theme.palette.Vegemite.main : theme.palette.Dark8.main),
    color: (props) => (props.isDarkMode ? theme.palette.Pavlova.main : theme.palette.Vegemite.main),
    textAlign: 'center',
  },
}));

export const SocialQuoteAndSource = ({ quoteAndSource, isDarkMode = false }: Props) => {
  const classes = useStyles({ isDarkMode });

  if (!quoteAndSource) {
    return null;
  }
  return (
    <ResponsiveGrid className={classes.root}>
      <Grid item md={12}>
        <StructuredText h2Variant="d5" pVariant="b3" data={quoteAndSource} />
      </Grid>
    </ResponsiveGrid>
  );
};
