import Marquee from 'react-fast-marquee';
import Image from 'next/image';
import { isMobile } from 'react-device-detect';
import { makeStyles, Grid, Theme } from '@material-ui/core';
import { P, Span } from 'components';
import { CustomerTestimonial } from 'services/cms';

type Props = {
  testimonials: CustomerTestimonial[];
  isDarkMode?: boolean;
};

const useStyles = makeStyles<Theme, Pick<Props, 'isDarkMode'>>((theme) => ({
  root: {
    backgroundColor: (props) => (props.isDarkMode ? theme.palette.Vegemite.main : theme.palette.Dark8.main),
    color: (props) => (props.isDarkMode ? theme.palette.Pavlova.main : theme.palette.Vegemite.main),
  },
  testimonialCard: {
    width: '312px',
    height: '201px',
    backgroundColor: (props) => (props.isDarkMode ? theme.palette.Dark1.main : theme.palette.Pavlova.main),
    margin: '0 20px',
    padding: '16px',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  profilePicture: {
    width: '48px',
    height: '48px',
    marginRight: '8px',
  },
  nameWrapper: {
    flex: 1,
  },
  marqueeWrapper: {
    padding: '72px 0 96px 0',
  },
  quote: {
    marginTop: '16px',
    color: (props) => (props.isDarkMode ? theme.palette.Dark5.main : theme.palette.Dark3.main),
  },
}));

export const SocialTestimonials = ({ testimonials, isDarkMode = false }: Props) => {
  const classes = useStyles({ isDarkMode });

  if (!testimonials || testimonials.length === 0) {
    return null;
  }

  return (
    <Grid className={classes.root}>
      <Grid className={classes.marqueeWrapper}>
        <Marquee gradient={false} play={true} pauseOnClick={true} pauseOnHover={!isMobile} speed={50}>
          {testimonials.map((testimonial, i) => {
            return (
              <div key={`${testimonial.name}-${i}`} className={classes.testimonialCard}>
                <Grid container wrap="nowrap" alignItems="center">
                  <div className={classes.profilePicture}>
                    <Image
                      loading="eager"
                      width="48px"
                      height="48px"
                      src={testimonial.profilePicture.url}
                      alt={testimonial.profilePicture.alt}
                      title={testimonial.profilePicture.title}
                    />
                  </div>
                  <Grid className={classes.nameWrapper} container direction="column">
                    <Span marginTop="none" variant="sub2">
                      {testimonial.name}
                    </Span>
                    {testimonial?.subText && (
                      <Span marginTop="none" variant="cap1">
                        {testimonial.subText}
                      </Span>
                    )}
                  </Grid>
                </Grid>
                <P marginTop="double" variant="b3" className={classes.quote}>
                  {testimonial.quote}
                </P>
              </div>
            );
          })}
        </Marquee>
      </Grid>
    </Grid>
  );
};
