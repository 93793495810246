import { Box, Grid, makeStyles } from '@material-ui/core';
import { MONTBLANC_FONT_FAMILY } from 'components/Layout/theme';
import { HomeloanRate } from 'services/cms';

type Props = {
  homeloanRates: Array<HomeloanRate>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.Blue.main,
    fontFamily: MONTBLANC_FONT_FAMILY,
    letterSpacing: '-0.02em',
  },
  rateBoxOuter: {
    backgroundColor: theme.palette.Blue.main,
    flex: '1',
    margin: '10px',
    padding: '80px 0',
    color: theme.palette.Pavlova.main,
    alignContent: 'center',
  },
  rateBoxInner: {
    width: 'fit-content',
  },
  rateText: {
    fontSize: '48px',
    lineHeight: '38px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '107px',
      lineHeight: '97px',
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: 'clamp(48px, 10vw, 107px)',
      lineHeight: 'clamp(38px, 10vw, 97px)',
    },
  },
  percentAnnumContainer: {
    width: 'fit-content',
  },
  percent: {
    fontSize: '20px',
    lineHeight: '20px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '62px',
      lineHeight: '51px',
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: 'clamp(20px, 4.333vw, 62px)',
      lineHeight: 'clamp(20px, 4.333vw, 51px)',
    },
  },
  pa: {
    fontSize: '20px',
    lineHeight: '20px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '35px',
      lineHeight: '30px',
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: 'clamp(20px, 4.333vw, 35px)',
      lineHeight: 'clamp(20px, 4.333vw, 30px)',
    },
  },
  description: {
    fontSize: '10px',
    lineHeight: '10px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '20px',
      lineHeight: '20px',
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: 'clamp(10px, 2.5vw, 20px)',
      lineHeight: 'clamp(10px, 2.5vw, 20px)',
    },
  },
  firstRow: {
    lineHeight: '100px',
  },
}));

export const HomeRatesBlade = (props: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container>
        {props.homeloanRates.map((homeloanRate, index) => (
          <Grid
            key={`${homeloanRate.rateType}-${homeloanRate.rate}`}
            container
            justifyContent="center"
            alignItems={index % 2 === 0 ? 'flex-start' : 'flex-end'}
            direction="column"
            className={classes.rateBoxOuter}
          >
            <div className={classes.rateBoxInner}>
              <Grid container wrap="nowrap" alignItems="center" direction="row" className={classes.firstRow}>
                <div className={classes.rateText}>{homeloanRate.rate}</div>
                <Grid container direction="column" className={classes.percentAnnumContainer}>
                  <div className={classes.percent}>%</div>
                  <div className={classes.pa}>P.A</div>
                </Grid>
              </Grid>
              <div className={classes.description}>{homeloanRate.rateType}</div>
            </div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
