import React, { useRef } from 'react';
import { Button, Grid, Hidden, makeStyles } from '@material-ui/core';
import { H2 } from 'components/typography';
import { Link } from '../../../components/link';
import { pages } from 'features/content/urls';
import { ResponsiveGrid } from 'components';
import { ArticlePreview } from '../types';
import { Article } from '.';
import { images } from '../urls';
import cx from 'classnames';

interface Props {
  articles: ArticlePreview[];
  title?: string;
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    padding: '0 0 10px 0',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    position: 'relative',
  },
  h2: {
    margin: 0,
  },
  button: {
    width: '40px',
    minWidth: '40px',
    height: '40px',
    backgroundColor: theme.palette.Vegemite.main,
    position: 'absolute',
    top: '120px',
    zIndex: 1,
    padding: 0,
    '&:hover': { backgroundColor: theme.palette.Dark2.main },
  },
  buttonLeft: { left: '-10px' },
  buttonRight: {
    left: 'auto',
    right: '-10px',
  },
  scrollbox: {
    overflowX: 'auto',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    scrollSnapType: 'x mandatory',
    '& > div': {
      scrollSnapAlign: 'start',
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiGrid-grid-xs-9': {
        minWidth: '75%',
      },
    },
    [theme.breakpoints.only('md')]: {
      '& .MuiGrid-grid-md-5': {
        minWidth: '41.666667%',
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& .MuiGrid-grid-md-5': {
        minWidth: '33.333333%',
      },
    },
  },
}));

export const ArticlesCarousel = ({ articles, title = 'Articles' }: Props) => {
  const classes = useStyles();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollButton = (dir: number) => {
    if (!scrollContainerRef.current) return;
    const itemWidth = scrollContainerRef.current.clientWidth / 3 + 1;
    scrollContainerRef.current.scrollTo({
      top: 0,
      left: scrollContainerRef.current.scrollLeft + itemWidth * dir,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.header}>
          <H2 variant="d6" className={classes.h2}>
            {title}
          </H2>
          <Link variant="button2" underline="always" href={pages.ARTICLES_PAGE}>
            See all
          </Link>
          <Hidden mdDown>
            <Button className={cx(classes.buttonLeft, classes.button)} onClick={() => scrollButton(-1)}>
              <img src={images.CHEVRON_LEFT} alt="Left" />
            </Button>
            <Button className={cx(classes.buttonRight, classes.button)} onClick={() => scrollButton(1)}>
              <img src={images.CHEVRON_RIGHT} alt="Right" />
            </Button>
          </Hidden>
        </div>
      </Grid>
      <Grid item xs={12}>
        <ResponsiveGrid noGutters gridContainerClassName={classes.scrollbox} ref={scrollContainerRef}>
          {articles.map((a) => (
            <Article mode="carousel" article={a} key={a.slug} />
          ))}
        </ResponsiveGrid>
      </Grid>
    </>
  );
};
